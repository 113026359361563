@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');

.Home {
  background-color: #607d8b;
  height: 100vh;
  .HomeText {
    font-size: 70px;
    text-align: center;
    padding-top: 20%;
    font-family: 'Roboto Mono', monospace;
  }
}

.navbar {
  background-color: #607d8b;
  position: fixed;
  z-index: 1;
  padding-left: 1%;
  width: 100vw;
  padding-top: 0px;
  margin-top: 0px;
}

.aboutMe {
  background-color: #607d8b;
  height: 100%;
  padding-top: 5%;
  margin-bottom: 0px;
  padding-bottom: 0px;

  .title {
    font-size: 30px;
    font-family: 'Roboto Mono', monospace;
  }
  .card {
    background-color: #cfd8dc;
    color: black;
    font-family: 'Roboto Mono', monospace;
  }
}

.skills {
  background-color: #607d8b;
  height: 175%;
  margin-top: 0%;
  padding-top: 0%;
  .p {
    margin: 0;
    font-family: 'Roboto Mono', monospace;
  }
  .img {
    height: 95px;
    width: 105px;
    padding-left: 5;
    border-radius: 50px;
    border: 0.5px solid black;
  }
  .skill-container {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto Mono', monospace;
  }
}

.Projects {
  background-color: #607d8b;
  height: 100vh;
  width: 100vw;
  margin-top: 0%;
  padding-top: 15%;
  margin-bottom: 0%;
  display: flex;
  justify-content: center;
  .projectImage {
    max-height: 600px;
  }
  .internal-text-projects {
    display: flex;
    flex-direction: row;
    justify-content: center;
    p {
      width: 400px;
      margin-left: 20px;
      color: white;
      font-size: 20px;
      font-family: 'Roboto Mono', monospace;
    }
    a {
      margin-left: 20px;
      font-family: 'Roboto Mono', monospace;
      text-decoration: none;
      color: 'black';
      font-size: 18px;
    }
    a:hover {
      text-decoration: underline;
    }
    .project-description {
      display: flex;
      flex-direction: column;
    }
  }
  .project-buttons {
    align-items: center;
    align-self: center;
  }
}

.contact {
  background-color: #607d8b;
  height: 100vh;
  padding-top: 20%;
  display: flex;
  justify-content: center;
  .contacts-grid {
    display: flex;
    justify-content: center;
  }
  .card {
    background-color: #cfd8dc;
  }
  .img {
    height: 100px;
    width: 100px;
  }
}

.interests {
  background-color: #607d8b;
  height: 100%;
  margin-top: 0px;
  padding-top: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 20px;
    font-family: 'Roboto Mono', monospace;
    background-color: #607d8b;
    color: white;
  }
}

@media only screen and (max-width: 1050px) {
  .Home {
    width: 1770px;
    margin-right: 0%;
    .HomeText {
      padding-top: 40%;
    }
  }
  .navbar {
    width: 1570px;
  }
  .aboutMe {
    width: 1570px;
    padding-right: 200px;
    .title {
      font-size: 30px;
      font-family: 'Roboto Mono', monospace;
    }
    .card {
      width: 750px;
    }
  }
  .skills {
    width: 1770px;
    padding-bottom: 10px;
    .skills-contaier {
      width: 900px;
    }
  }
  .Projects {
    width: 1770px;
    display: flex;
    justify-content: center;
    .projectImage {
      max-height: 500px;
      padding-top: 25px;
    }
    .internal-text-projects {
      p {
        width: 400px;
      }
    }
  }
  .contact {
    width: 1770px;
    padding-top: 20%;
  }
  .interests {
    width: 1770px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 770px) {
  .Home {
    width: 1070px;
    margin-right: 0%;
    .HomeText {
      padding-top: 40%;
    }
  }
  .navbar {
    width: 900px;
  }
  .aboutMe {
    width: 900px;
    padding-right: 170px;
    .title {
      font-size: 30px;
      font-family: 'Roboto Mono', monospace;
    }
    .card {
      width: 750px;
    }
  }
  .skills {
    width: 1070px;
  }
  .Projects {
    width: 1070px;
    display: flex;
    justify-content: center;
    .projectImage {
      max-height: 300px;
      padding-top: 25px;
    }
    .internal-text-projects {
      p {
        width: 350px;
      }
    }
  }
  .contact {
    width: 1070px;
    padding-top: 20%;
  }
  .interests {
    width: 1070px;
  }
}

@media only screen and (max-width: 390px) {
  .Home {
    width: 400px;
    margin-right: 0%;
    .HomeText {
      padding-top: 70%;
    }
  }
  .navbar {
    display: none;
  }
  .aboutMe {
    width: 400px;
    .title {
      font-size: 30px;
      font-family: 'Roboto Mono', monospace;
    }
    .card {
      width: 300px;
      padding-right: 0px;
      img {
        display: none;
      }
    }
  }
  .skills {
    width: 400px;
    height: 100%;
  }
  .Projects {
    width: 400px;
    display: flex;
    justify-content: center;
    .projectImage {
      display: none;
    }
    .internal-text-projects {
      p {
        width: 350px;
        padding-right: 50px;
      }
    }
  }
  .contact {
    width: 400px;
    .contacts-grid {
      display: flex;
      flex-direction: column;
      width: 275px;
    }
  }
  .interests {
    width: 400px;
    height: 100vh;
    margin-top: 0px;
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 16px;
      font-family: 'Roboto Mono', monospace;
      background-color: #607d8b;
      color: white;
    }
  }
}

// @media only screen and (max-height: 800px) {
//   .Home {
//     height: 900px;
//     width: 1200px;
//     margin-right: 0%;
//     .HomeText {
//       padding-top: 20%;
//     }
//   }
//   .aboutMe {
//     height: 100%;
//     width: 1200px;
//     .title {
//       font-size: 30px;
//       font-family: 'Roboto Mono', monospace;
//     }
//     .card {
//       width: 800px;
//       padding-right: 0px;
//     }
//   }
//   .skills {
//     width: 1200px;
//     height: 100%;
//   }
//   .Projects {
//     width: 1200px;
//     height: 160vh;
//     display: flex;
//     justify-content: center;
//     padding-bottom: 150px;
//     .projectImage {
//       width: 500px;
//       height: 350px;
//     }
//     .internal-text-projects {
//       p {
//         width: 350px;
//         padding-right: 50px;
//       }
//     }
//   }
//   .contact {
//     width: 1200px;
//     padding-bottom: 50px;
//   }
//   .interests {
//     width: 1200px;
//     height: 180vh;
//     margin-top: 0px;
//     padding-top: 0px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     .title {
//       font-size: 16px;
//       font-family: 'Roboto Mono', monospace;
//       background-color: #607d8b;
//       color: white;
//     }
//   }
// }
